import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Seo from '../components/seo';
import TextBanner from "../components/text-banner/text-banner";
// import FiftyfiftyTextBanner from '../components/fiftyfifty-text-banner/fiftyfifty-text-banner';
import OverlayBlockTwoColumns from '../components/overlay-block-two-columns/overlay-block-two-columns';
import Multicolumn from '../components/multicolumn/multicolumn';
import OverlayBlock from '../components/overlay-block/overlay-block';

class AboutUs extends React.Component {
  render() {
    const aboutUsTextBanner = get(this, 'props.data.aboutUsTextBanner');
    const aboutUsFiftyFiftyTextBanner = get(this, 'props.data.aboutUsFiftyFiftyTextBanner');
    const aboutUsOverlayBlockTwoColumns = get(this, 'props.data.aboutUsOverlayBlockTwoColumns');
    const aboutUsMulticolumnHeading = get(this, 'props.data.aboutUsMulticolumnHeading');
    const aboutUsMulticolumnComponent = get(this, 'props.data.aboutUsMulticolumnComponent');
    const aboutUsOverlayBlock = get(this, 'props.data.aboutUsOverlayBlock');
    return (
      <Layout location={this.props.location}>
        <Seo title="About" />
          <div className="container-fluid container-fluid--page">
            <TextBanner textOne={aboutUsTextBanner.textOne} textTwo={aboutUsTextBanner.textTwo} />
            {/* <FiftyfiftyTextBanner bannerTextLeft={aboutUsFiftyFiftyTextBanner.bannerTextLeft.bannerTextLeft} bannerTextRight={aboutUsFiftyFiftyTextBanner.bannerTextRight.bannerTextRight} buttonPrimaryUrl={aboutUsFiftyFiftyTextBanner.buttonPrimaryUrl} buttonPrimaryText={aboutUsFiftyFiftyTextBanner.buttonPrimaryText} buttonSecondaryUrl={aboutUsFiftyFiftyTextBanner.buttonSecondaryUrl} buttonSecondaryText={aboutUsFiftyFiftyTextBanner.buttonSecondaryText} /> */}
            <OverlayBlockTwoColumns bannerTextLeft={aboutUsOverlayBlockTwoColumns.bannerTextLeft.bannerTextLeft} bannerTextRight={aboutUsOverlayBlockTwoColumns.bannerTextRight} buttonPrimaryUrl={aboutUsOverlayBlockTwoColumns.buttonPrimaryUrl} buttonPrimaryText={aboutUsOverlayBlockTwoColumns.buttonPrimaryText} buttonSecondaryUrl={aboutUsOverlayBlockTwoColumns.buttonSecondaryUrl} buttonSecondaryText={aboutUsOverlayBlockTwoColumns.buttonSecondaryText} image={aboutUsOverlayBlockTwoColumns.image.url} />
            <Multicolumn multicolumnHeading={aboutUsMulticolumnHeading} multicolumn={aboutUsMulticolumnComponent.nodes} />
            <OverlayBlock headingText={aboutUsOverlayBlock.title} buttonUrl={aboutUsOverlayBlock.buttonUrl} buttonText={aboutUsOverlayBlock.buttonText} image={aboutUsOverlayBlock.backgroundImage.url} />
          </div>
      </Layout>
    )
  }
}

export default AboutUs;

export const query = graphql`
query aboutUsQuery {
  aboutUsTextBanner: contentfulTextBanner(textBannerName: {eq: "aboutUsTextBanner"}) {
    textBannerName
    textOne
    textTwo
  }
  aboutUsFiftyFiftyTextBanner: contentfulFiftyFiftyTextBanner(fiftyFiftyTextBannerName: {eq: "aboutUsFiftyFiftyTextBanner"}) {
    fiftyFiftyTextBannerName
    bannerTextLeft {
      bannerTextLeft
    }
    bannerTextRight {
      bannerTextRight
    }
    buttonPrimaryUrl
    buttonPrimaryText
    buttonSecondaryUrl
    buttonSecondaryText
  }
  aboutUsOverlayBlockTwoColumns: contentfulOverlayBlockTwoColumns(overlayBlockTwoColumnsName: {eq: "aboutUsOverlayBlockTwoColumns"}) {
      bannerTextLeft {
        bannerTextLeft
      }
      bannerTextRight {
        raw
      }
      buttonPrimaryText
      buttonPrimaryUrl
      buttonSecondaryText
      buttonSecondaryUrl
      image {
        url
      }
  }
  aboutUsMulticolumnHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "aboutUsMulticolumnHeading"}) {
    titleText
    descriptionText
  }
  aboutUsMulticolumnComponent: allContentfulMulticolumnComponent (sort: { fields: orderId, order: ASC }) {
    nodes {
      orderId
      headingText
      labelText
      image {
        url
      }
      descriptionText
    }
  }
  aboutUsOverlayBlock: contentfulOverlayBlock(overlayBlockName: {eq: "aboutUsOverlayBlock"}) {
    overlayBlockName
    title
    buttonUrl
    buttonText
    backgroundImage {
			url
    }
  }
}
`
