import React from 'react'
import { Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import './overlay-block-two-columns.scss'

export default function OverlayBlockTwoColumns(props) {
  return (
    <div className="overlay-block-two-columns" style={{backgroundImage: `url(${props.image})`}}>
        <div className="container-fluid">
            <div className="overlay-block-two-columns__box">
                <div className="overlay-block-two-columns__container">
                    <p className="subtitle">{props.bannerTextLeft}</p>
                </div>

                <div className="overlay-block-two-columns__container">
                    <div className="description">{props.bannerTextRight?.raw && renderRichText(props.bannerTextRight)}</div>
                    <div className="ff-text-banner__right__button-container">
                        {props.buttonPrimaryText &&
                            <Link to={props.buttonPrimaryUrl} className="button-primary">{props.buttonPrimaryText}</Link>
                        }
                        {props.buttonPrimaryText &&
                            <Link to={props.buttonSecondaryUrl} className="button-secondary button-secondary--dark">{props.buttonSecondaryText}</Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
